import React, { useEffect, useState } from 'react';
import * as styles from './Tabs.module.css';
import clsx from 'clsx';

export default function Tabs({ className, content}) {
  const [tabVisible, setTabVisible] = useState(0);
  const [tabHead, setTabHead] = useState([]);
  const [tabContent, setTabContent] = useState([]);

  useEffect(() => {
    let head = [];
    let tab = [];

    content.map((item, index) => {
      head.push(
        <button key={ `tabH${index}` }
          id={ `tabH${index}` }
          className={ clsx(styles.tab, tabVisible === index && styles.selected) }
          onClick={() => {tabChange_handler(index);}}
          onKeyDown={() => {tabChange_handler(index);}}
          role="tab"
          aria-selected="true"
          aria-controls={`panel-${index + 1}`}>
          <span className={styles.tabText}>
            { item.title }
          </span>
        </button>
      );
      tab.push(
        <div key={ `tabC${index}` }
          id={`panel-${index + 1}`}
          className={ clsx(styles.content, tabVisible === index && styles.open) }
          role="tabpanel"
          aria-labelledby={ `tabH${index}` }
        >
          { item.text }
        </div>
      );
      return true;
    });

    setTabHead(head);
    setTabContent(tab);
  }, [content, tabVisible]);

  const tabChange_handler = (index) => {
    setTabVisible(index);
  };

  return (
    <div className={ clsx(className) }>
      <div className={ styles.header}>
        { tabHead }
      </div>
      { tabContent }
    </div>
  );
}
