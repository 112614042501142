import React from 'react';
import * as styles from './DownloadButton.module.css';
import clsx from 'clsx';

export default function DownloadButton({ className, children, darkmode=false, file, title }) {

  return (
    <a className={ clsx(styles.button, className, darkmode && styles.darkmode)} href={file} target='_blank' title={ title } rel="noreferrer">
      <div className={ styles.text }>{ children }</div>
    </a>
  );
}